// custom typefaces
import "typeface-montserrat"
import "typeface-merriweather"

// normalize CSS across browsers
import "./src/css/normalize.css"

// custom CSS styles
import "./src/css/style.css"


// gatsby-browser.js

// import React from "react"

// Function to add the link to Google Font
const addGoogleFontLink = () => {
  // Create a new link element
  const link = document.createElement("link")
  link.href = "https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap"
  link.rel = "stylesheet"
  // Append the link to the document's head
  document.head.appendChild(link)
}

// Execute the function when the initial client render happens
export const onInitialClientRender = () => {
  addGoogleFontLink()
}
