exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-dashboard-2-jsx": () => import("./../../../src/pages/dashboard2.jsx" /* webpackChunkName: "component---src-pages-dashboard-2-jsx" */),
  "component---src-pages-dashboard-authattempt-jsx": () => import("./../../../src/pages/dashboard_authattempt.jsx" /* webpackChunkName: "component---src-pages-dashboard-authattempt-jsx" */),
  "component---src-pages-dashboard-jsx": () => import("./../../../src/pages/dashboard.jsx" /* webpackChunkName: "component---src-pages-dashboard-jsx" */),
  "component---src-pages-demo-jsx": () => import("./../../../src/pages/demo.jsx" /* webpackChunkName: "component---src-pages-demo-jsx" */),
  "component---src-pages-demo-simple-jsx": () => import("./../../../src/pages/demo_simple.jsx" /* webpackChunkName: "component---src-pages-demo-simple-jsx" */),
  "component---src-pages-go-jsx": () => import("./../../../src/pages/go.jsx" /* webpackChunkName: "component---src-pages-go-jsx" */),
  "component---src-pages-goodbye-jsx": () => import("./../../../src/pages/goodbye.jsx" /* webpackChunkName: "component---src-pages-goodbye-jsx" */),
  "component---src-pages-home-jsx": () => import("./../../../src/pages/home.jsx" /* webpackChunkName: "component---src-pages-home-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-login-jsx": () => import("./../../../src/pages/login.jsx" /* webpackChunkName: "component---src-pages-login-jsx" */),
  "component---src-pages-map-jsx": () => import("./../../../src/pages/map.jsx" /* webpackChunkName: "component---src-pages-map-jsx" */),
  "component---src-pages-map-vg-jsx": () => import("./../../../src/pages/map_vg.jsx" /* webpackChunkName: "component---src-pages-map-vg-jsx" */),
  "component---src-pages-map-voz-jsx": () => import("./../../../src/pages/map-voz.jsx" /* webpackChunkName: "component---src-pages-map-voz-jsx" */),
  "component---src-pages-my-account-login-jsx": () => import("./../../../src/pages/my-account-login.jsx" /* webpackChunkName: "component---src-pages-my-account-login-jsx" */),
  "component---src-pages-my-account-register-jsx": () => import("./../../../src/pages/my-account-register.jsx" /* webpackChunkName: "component---src-pages-my-account-register-jsx" */),
  "component---src-pages-ourstory-jsx": () => import("./../../../src/pages/ourstory.jsx" /* webpackChunkName: "component---src-pages-ourstory-jsx" */),
  "component---src-pages-pricing-jsx": () => import("./../../../src/pages/pricing.jsx" /* webpackChunkName: "component---src-pages-pricing-jsx" */),
  "component---src-pages-product-jsx": () => import("./../../../src/pages/product.jsx" /* webpackChunkName: "component---src-pages-product-jsx" */),
  "component---src-pages-shop-jsx": () => import("./../../../src/pages/shop.jsx" /* webpackChunkName: "component---src-pages-shop-jsx" */),
  "component---src-pages-upload-jsx": () => import("./../../../src/pages/upload.jsx" /* webpackChunkName: "component---src-pages-upload-jsx" */),
  "component---src-templates-account-template-js": () => import("./../../../src/templates/account-template.js" /* webpackChunkName: "component---src-templates-account-template-js" */),
  "component---src-templates-blog-post-archive-js": () => import("./../../../src/templates/blog-post-archive.js" /* webpackChunkName: "component---src-templates-blog-post-archive-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-page-post-js": () => import("./../../../src/templates/page-post.js" /* webpackChunkName: "component---src-templates-page-post-js" */),
  "component---src-templates-product-post-js": () => import("./../../../src/templates/product-post.js" /* webpackChunkName: "component---src-templates-product-post-js" */)
}

